import React from 'react'
import { Link } from 'gatsby'
import Codepen from '../../components/embedCodepen'
import Layout from '../../components/layout'
import Header from '../../components/header'
import { Helmet } from 'react-helmet';





const Portfolio1 = ({data}) => (
  <Layout>
      <Codepen/>
      <main className="page--demo demo--trianglefusion">
      <Helmet>
        <title>{data.site.siteMetadata.demoTriangleTitle} | {data.site.siteMetadata.title}</title>
      </Helmet>
        <section className="slab slab--main">
            <Header siteTitle="Rob DiMarzo" /> 

            <div className="slab__inner">
                <div className="pageblock__content">   
                  <div className="pageblock__title">
                      <h1>{data.site.siteMetadata.demoTriangleTitle} </h1>
                  </div>  
                    <p className="text--bold">A demo to infinitely animate inline SVGs within a hexagonal grid layout. </p> 
                    <p>
                        Triangles are created. Triangles are destroyed. And so on and so forth.
                    </p>

                    <p className="text--bold">What's going on here?</p>
                    <p>
                    In this pen, I am creating 60 SVGs that each contain 4 triangles (polygons). Each polygon loops through a color and animates outward from the center point of its parent SVG every 1 second. The entire animation repeats infinitely every 4 seconds.
                    </p>

                    <p className="text--bold">
                    How are the triangles combining?
                    </p>
                    <p>
                    Each div has a clip-path to create a hexagon mask. When the hexagons are snapped together, the expanding triangles bleed evenly into the triangles of the adjacent hexagons until they disappear.
                    </p>
                    <p className="text--bold">
                    How are the hexagons connected?
                    </p>
                    <p>
                    I used CSS Grid as a starting point, but I was still left with a bunch of well-mannered hexagons with empty space in between. To "connect" the hexagons, I identified the nth-children that corresponds to a row and moved them up and over via transform: translate;
                    </p>

                </div>
                <div className="pageblock__demo">
                    <p className="codepen" data-height="700" data-theme-id="36208" data-default-tab="result" data-user="robdimarzo" data-slug-hash="VqjvqR" data-pen-title="Infinite SVG Triangle Fusion"> See the Pen <a href="https://codepen.io/robdimarzo/pen/VqjvqR/">
                    Infinite SVG Triangle Fusion</a> by Rob DiMarzo (<a href="https://codepen.io/robdimarzo">@robdimarzo</a>)
                    on <a href="https://codepen.io">CodePen</a>.
                    </p>
                </div>
            </div>
        </section>
    </main>
  </Layout>
)
export const query = graphql`
  query TriangleQuery {
    site {
      siteMetadata {
        title
        demoTriangleTitle
      }
    }
  } 
`
export default Portfolio1
